<template>
  <div>
    <div v-if="items.length">
      <div class="row ml-0 mr-0">
        <div v-for="(elem, index) in items" :key="index" class="col-6 col-lg-3">
          <div class="wrap-card">
            <b-badge
              v-if="elem.plant && elem.plant.redBook"
              variant="danger redBook"
            >
              {{ $t('herbarium_form_page_redBook') }}
            </b-badge>
            <b-card
              :title="languageTake === 'ru' ? elem.ruName : elem.enName"
              :img-src="`${elem.image}`"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
              @click="goTodetail(elem.id)"
            >
              <b-card-text>
                {{ languageTake === 'en' ? elem.ruName : elem.enName }}
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading && !items.length" class="pb-5">
      <div class="m-auto">
        <b-spinner variant="success" label="Text Centered"></b-spinner>
      </div>
    </div>
    <div v-if="!items.length && !$route.query" class="text-center">
      <h2 class="MainPageContentNoData mt-5">
        {{ $t('main_page_no_herbarium') }}
      </h2>
    </div>
    <div v-if="!items.length && $route.query" class="text-center">
      <h2 class="MainPageContentNoData mt-5">
        {{ $t('main_page_no_search') }}
      </h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters(['languageTake'])
  },
  methods: {
    goTodetail(itemId) {
      this.$router
        .push({ name: 'herbariumItem', params: { id: itemId } })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.col-6 {
  padding-right: 5px;
  padding-left: 5px;
}
.col-sm-3 {
  padding-right: 5px;
  padding-left: 5px;
}
.wrap-card {
  cursor: pointer;
  position: relative;
  .redBook {
    position: absolute;
    top: 0%;
    right: 0%;
    z-index: 100;
  }
  .card {
    img {
      height: 352px;
    }
  }
}
</style>
